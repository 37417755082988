.modal {
    top:70px;
    width:80%;
}
.modal-dialog {
    width: 600px;
    margin: 30px auto;
}
.modal-content {
    width:190%;
}
.modal-title {
    font-weight: 500 !important;
}
.cust-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #0000003b;
    border-radius: 4px;
}