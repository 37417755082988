
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$preceptor-app-primary: mat-palette($mat-indigo);
$preceptor-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$preceptor-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$preceptor-app-theme: mat-light-theme((
  color: (
    primary: $preceptor-app-primary,
    accent: $preceptor-app-accent,
    warn: $preceptor-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($preceptor-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


// ======================================================================

@import './app.scss';
@import '../preceptors-list/preceptors-list.component.scss';
@import '../confirmation-dialog/confirmation-dialog.component.scss';
@import '../preceptor-form/preceptor-form.component.scss';
@import '../components/datepicker-input/datepicker-input.component.scss';
@import './spinner.scss';
@import './toaster.scss';

:root {
  --rp-dark-green:  #005641;
  --rp-beige: #f2eae1;
  --rp-dark-beige: #e3d5cd;
  --rp-mdeium-green: #5e927f;
  --rp-light-green: #b1c77e;
  --rp-maroon: #4e1d39;
}
