@font-face {
    font-family: 'Open Sans Bold';
    src: url('/Fonts/OpenSans-Bold-webfont.eot');
    src: url('/Fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('/Fonts/OpenSans-Bold-webfont.woff') format('woff'), url('/Fonts/OpenSans-Bold-webfont.ttf') format('truetype'), url('/Fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('/Fonts/OpenSans-Light-webfont.eot');
    src: url('/Fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), url('/Fonts/OpenSans-Light-webfont.woff') format('woff'), url('/Fonts/OpenSans-Light-webfont.ttf') format('truetype'), url('/Fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/Fonts/OpenSans-Regular-webfont.eot');
    src: url('/Fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('/Fonts/OpenSans-Regular-webfont.woff') format('woff'), url('/Fonts/OpenSans-Regular-webfont.ttf') format('truetype'), url('/Fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Oswald";
    src: url("/Fonts/Oswald.eot?") format("eot"), url("/Fonts/Oswald.woff") format("woff"), url("/Fonts/Oswald.ttf") format("truetype"), url("/Fonts/Oswald.svg#Oswald") format("svg");
    font-weight: normal;
    font-style: normal;
}

html {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

body {
    margin: 0;
    padding: 0;
    background: #EDEDED;
    font-family: 'Open Sans', sans-serif;
}

// header {
//     background: #0A48B1;
// }
.logo {
    text-align: left;
}

.preceptor_text {
    font-family: "Oswald";
    color: #fff;
    text-align: right;
    font-size: 18px;
    margin-top: 44px;
}

footer {
    background: #2D3A4B;
    padding: 10px 0;
}

.content-wrapper {
    background: #fff;
    margin-bottom: 100px;
    border-top: #A5CBDE 1px solid;
}

.preceptor_form {
    margin-top: 100px;
}

.no-padding {
    padding: 0;
}

.header {
    background: #FFF;
}

.logo {
    text-align: left;
}

.preceptor_text {
    font-family: "Oswald";
    color: #fff;
    text-align: right;
    font-size: 18px;
    margin-top: 44px;
}

footer {
    background: #2D3A4B;
    padding: 10px 0;
}

.view-icon {
    background: url("/Images/eyes.png") no-repeat;
    display: inline-block;
    height: 16px;
    width: 26px;
    cursor: pointer;
}

.edit-icon {
    background: url("/Images/edit.png") no-repeat;
    display: inline-block;
    height: 20px;
    width: 26px;
    cursor: pointer;
}

.delete-icon {
    background: url("/Images/delete.png") no-repeat;
    display: inline-block;
    height: 20px;
    width: 26px;
    cursor: pointer;
    margin-left: 10px;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.detail-label {
    font-family: 'Open Sans';
    font-size: 16px;
    margin-bottom: 10px;
}

.detail-data {
    font-family: 'Open Sans Bold';
    font-size: 16px;
}

.loading-spiner-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1060;
    background-color: #000;
    opacity: .8;
}

.loading-spiner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
}

.addprec {
    display: inline-block;
    vertical-align: middle;
    color: #4B5461;
    margin-top: 27px;
    margin-bottom: 19px;
    font-size: 18px;
}

.addprec:hover,
.addprec:active,
.addprec:visited {
    color: #4B5461;
    text-decoration: none;
}

.addprec:before {
    content: url('/Images/add.png');
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.form-group.required .control-label:after {
    content: "*";
    color: red;
}

.form-padding {
    padding: 40px 30px;
}

.form-group {
    margin-bottom: 30px;
}

.has-error {
    font-size: 12px;
    color: red;
    font-weight: bold;
}

.searchBar {
    padding: 10px 10px;
}

.searchBar .preceptorSearch {
    background-image: url("/Images/search.png");
    background-repeat: no-repeat;
    padding-left: 50px;
    background-position: 10px 7px;
    margin-top: 7px;
}

.home-back:before {
    //  content: url('/Images/home.png');
    margin-right: 0;
}

.pager-select {
    width: 75px;
}

.detail-data pre {
    display: block;
    width: 100%;
    background: inherit;
    font-family: "Open Sans Bold";
    font-size: 16px;
    border: none;
    padding: 0;
}


/* ==================================================================*/

header .header-row .container .header_logo img {
    display: block;
    width: 250px;
    margin-left: -20px;
}

header {
    width: 100%;
    z-index: 9990;
    top: 0px;
    position: fixed;
    background: #fff;
    .header-serach-bar {
        margin-top: 20px;
    }
    .header-row {
        background: rgba(255, 255, 255, 0.93);
        border-bottom: solid 1px #ccc;
        /*padding: 15px 0;*/
        .header-container {
            min-width: 1340px !important;
            margin-right: auto;
            margin-left: auto;
        }
        .container {
            /*max-width: 1141px;*/
            .header_logo {
                padding: 0;
                img {
                    display: block;
                    width: 250px;
                    margin-left: -20px;
                }
            }
            .searchUniversity {
                display: inline-block;
                input {
                    background: #f9f9f9;
                    vertical-align: middle;
                    outline: none;
                    border: none;
                    padding: 9px;
                    width: 254px;
                    height: 30px;
                }
                .dropdown-menu {
                    max-height: 500px;
                    overflow-y: auto;
                    z-index: 9999;
                }
            }
            .search-button {
                display: inline-block;
                padding-left: 5px;
                input {
                    vertical-align: middle;
                    border: none;
                    //background: url(/Images/search-icn.png) center no-repeat #f9f9f9;
                    width: 33px;
                    height: 30px;
                }
            }
        }
        .logo-wrap {
            width: 240px;
            background-color: #2680BB;
            height: 80px;
            padding: 10px 10px 10px 0;
            float: left;
        }
        .header_menu {
            float: left;
            .header_navs {
                float: left;
                color: #fff;
                margin-left: 28px;
                margin-bottom: 0;
                font-family: roboto-regular;
                height: 65px;
                li {
                    padding-top: 20px;
                    font-size: 16px;
                    text-align: initial;
                    margin-right: 50px;
                    height: 65px;
                    a {
                        text-decoration: none;
                        color: white;
                        .liname {
                            font-family: roboto-regular;
                            height: 45px;
                        }
                        .borderactive {
                            height: 2px;
                            background-color: white !important;
                            width: 100%;
                        }
                    }
                }
            }
            .user-info {
                padding-top: 13px;
                color: #fff;
                margin-right: 23px;
                .user-image {
                    //  background: url('/Images/image_asset.png') 0 1px;
                    height: 33px;
                    width: 33px;
                    float: left;
                }
                .user_div {
                    float: left;
                    text-align: left;
                    margin-left: 7px;
                    .welcome {
                        font-size: 12px;
                    }
                    .user_name {
                        font-size: 14px;
                    }
                }
                .caret-image {
                    //background: url('/Images/image_asset.png') -55px 5px;
                    margin-top: 13px;
                    margin-left: 31px;
                    height: 15px;
                    width: 18px;
                    float: left;
                }
            }
        }
    }
}

//.header_navs>li>a {text-decoration:none;}
.header_navs>li.menuactive,
.header_navs>li:hover {
    border-bottom: 4px solid white !important;
    cursor: pointer;
}

#middle-wrapper {
    min-height: 700px;
    top: 80px;
    position: relative;
}

#logout .userSelectBox+.popover .popover-content {
    padding: 5px 10px;
    cursor: pointer;
}

div {
    border-radius: 0 !important;
}

#logout .userSelectBox {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: var(--rp-dark-green) !important;
    border: none;
    outline: 0;
    padding-left: 0;
    background-color: #fff;
}

.sort-ascent:after {
    content: url(/Images/sort-icn.png) !important;
    padding: 2px;
    cursor: pointer;
}

.sort-descent:after {
    padding: 2px;
    content: url(/Images/sort-ic-down-up.png) !important;
    cursor: pointer;
}

tbody tr {
    cursor: pointer;
}

.ng-toast {
    z-index: 9999 !important;
}

.phone_number {
    white-space: nowrap;
}

#logout {
    .welcomeText {
        font-family: Rubik, sans-serif;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a4a4a4;
        margin-top: 20px;
    }
    .userSelectBox {
        //  font-family: Rubik, sans-serif !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        font-style: normal !important;
        font-stretch: normal !important;
        line-height: normal !important;
        letter-spacing: normal !important;
        text-align: left !important;
        color: var(--rp-dark-green) !important;
        border: none;
        outline: none;
        padding-left: 0px;
        background-color: white;
    }
    .userSelectBox:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 6px solid var(--rp-dark-green);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 1px;
    }
    .userSelectBox+.popover.bottom {
        width: 150px;
        left: auto !important;
        margin-top: 0px !important;
    }
    .popover.bottom>.arrow {
        display: none;
    }
    .userSelectBox+.popover .popover-content {
        padding: 5px 10px;
        cursor: pointer;
    }
}

.checkbox_name {
    margin-left: 4px;
    position: relative;
    padding-left: 5px;
    top: -3px;
}

.back_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #4b5461;
    margin-top: 27px;
    margin-bottom: 19px;
    font-size: 18px;
}

.dtheader {
    font-weight: 500;
    height: 40px;
}

table.dataTable thead th {
    font-weight: 500;
    border-bottom: 2px solid #ddd;
    padding: 8px;
    line-height: 1.42857143;
    width: auto;
    padding: 2px;
    width: 50px !important;
    background-repeat: no-repeat;
    background-position: right 25px !important;
}

.phonenum {
    white-space: nowrap;
}

.datehide {
    display: none;
}