
//program filters

/* linkbox - default structure */
.linkbox {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.linkbox {
    -webkit-transition: 1s;
    transition: 1s;
    display: table;
    background: #fff;
    width: 100%;
    color: #727272;
    text-decoration: none;
}

.linkbox:hover, .linkbox:focus {
    color: #092c58;
    text-decoration: none;
}

.linkbox > div {
    display: table-cell;
    vertical-align: middle;
}

.linkbox > .linkbox-left {
    width: 100%;
    padding: 20px 21px;

    .filter-group-title-selected {
        color: #092c58;
    }
}

.filter-group-title-checked {
    color: #092c58;
}

.linkbox > .linkbox-right {
    white-space: nowrap;
    padding-right: 19px;
}


/* mid left */
.midLeft {
    display: table-cell;
    vertical-align: top;
    max-width: 264px;
    padding-right: 20px;
}

body.online-programs .midLeft {
    max-width: 100%;
    width: 22.2%;
}

/* mid right */
.midRight {
    display: table-cell;
    vertical-align: top;
    width: 100%;
}

body.programs .midRight {
    width: 390px;
    padding-left: 20px;
}

.program-filter .linkbox {
    border-left: solid 1px #e0e0e0;
    border-right: solid 1px #e0e0e0;
    border-bottom: solid 1px #e0e0e0;
    font-size: 1.1em;
    line-height: 100%;
}

.program-filter .linkbox.header {
    border-bottom: solid 1px #e0e0e0;
    background: var(--rp-dark-green) !important;
    font-size: 1.21em;
    color: #fff;
}

.program-filter .clear-filter {
    background: #fff;
    font-size: .7em;
    color: #092c58;
    font-weight: 300;
    text-transform: uppercase;
    padding: 5px;
}

.program-filter .linkbox select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    border: none;
    background: url(/Resource/images/linkbox-arrow-closed.png) no-repeat right center;
    width: 100%;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 120% !important;
    color: #727272;
    cursor: pointer;
}

.program-filter .linkbox select option {
    font-size: .75em;
}

.program-filter .show-content {
    border-left: solid 1px #e0e0e0;
    border-right: solid 1px #e0e0e0;
    border-bottom: solid 1px #e0e0e0;
    background: #f3f3f3;
    font-size: .85em;
    padding: 15px;
    min-height: 65px;
}

.program a {
    color: inherit;
}

.program a:hover, .program a:focus {
    text-decoration: none;
}

/* filter results */
.degree-results {
    font-size: .75em;
    color: #a5a5a5;
}

.active-filters {
    overflow: hidden;
    width: 100%;
    min-height: 42px;
    padding-bottom: 20px;
}

.active-filters ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.active-filters ul li {
    display: inline-block;
    border: solid 1px #e0e0e0;
    background: #fff;
    font-size: .8em;
    color: #092c58;
    padding: 2px 5px;
    margin-right: 5px;
    cursor: pointer;
}

.filter-results {
    margin: 0 0 17px;
}

.filter-results .result-bucket {
    float: left;
    overflow: hidden;
    width: 100%;
    margin: 0 2% 2% 0;
}

.filter-results .result-bucket:nth-of-type(3n) {
    margin-right: 0;
}

.filter-results .result-bucket:nth-of-type(3n+1) {
    clear: both;
}

.filter-results .result-content {
    border: solid 1px #c0c0c0;
}

.filter-results .result-bucket:last-child {
    padding-right: 0;
}

.result-bucket .logo {
    display: table;
    background: #f8f8f8;
    width: 100%;
    height: 71px;
}

.result-bucket .logo-container {
    display: table-cell;
    vertical-align: middle;
}

.result-bucket .logo img {
    display: block;
    max-width: 185px;
    width: auto;
    margin: 0 auto;
}

.result-bucket .program {
    background: #fff;
    border-top: solid 1px #c0c0c0;
    padding: 21px;
}

.result-bucket a {
    color: inherit;
}

.result-bucket a:hover {
    text-decoration: none;
}

.program-title {
    overflow: hidden;
    height: 62px;
    font-size: 1.1em;
    line-height: 120%;
    color: #000;
    margin: 0 0 20px;
}

span.truncate {
    display: block;
    width: 100%;
}

.standard-checkbox {
    display: inline-block;
    width: 13px;
}

.standard-checkbox + label {
    display: inline-block;
    width: 160px;
    vertical-align: top;
    color: #092c58;
    margin-top: 1px;
    font-weight: normal;
    font-size: 15px;
}

.no-results-found {
    font-size: 22px;
    color: #a5a5a5;
    margin-top: 20px;
}

.onlineDegreeResult {
    text-align: center;
}

.checkbox-filter-group {
    border-left: solid 1px #e0e0e0;
    border-right: solid 1px #e0e0e0;
    border-bottom: solid 1px #e0e0e0;
    background: #f3f3f3;
    font-size: .85em;
    padding: 15px 10px 15px 14px;
    min-height: 65px;
    overflow-y: auto;
    max-height: 200px;
    overflow-x: hidden;
    margin-bottom: 2px;
}

.animate-show-hide.ng-hide {
    opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
    transition: all linear 0.5s;
}

.filter-group {
    cursor: pointer;
    width: 220px;
}

.pd-rt-10 {
    padding-right: 10px;
}

.header1 {
    font-size: 1.5em;
    line-height: 120%;
    color: #494949;
    margin: 0 0 10px;
}

.onlineDegreeCount {
    font-size: 1.5em;
    margin: 0;
}

.filter-div {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;

    a {
        text-decoration: underline;
        color: cornflowerblue;
    }
}

.ct-link {
    margin-top: 30px;
    margin-bottom: 30px;

    .degree-results {
        text-decoration: underline;
    }
}

.headings {
    color: #337ab7;
    font-size: 27px;
    font-weight: 600;
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
}

/* align glyph */
.right-addon .glyphicon {
    right: 0px;
}

/* add padding  */
.right-addon input {
    padding-right: 30px;
}

.panel-heading {
    background-color: #333A4C;
    color: #fff;
    height: 35px;
    padding: 0;
    border: none;
}

.mg-top-20 {
    margin-top: 20px;
}

.mg-left-5 {
    margin-left: 5px;
}

.mg-top-cut-10 {
    margin-top: -10px;
}

.mg-top-cut-5 {
    margin-top: -5px;
}

.mg-top-10 {
    margin-top: 10px;
}

.mg-bottom-30 {
    margin-bottom: 30px;
}

.mg-top-40 {
    margin-top: 40px;
}

.ft-16 {
    font-size: 16px;
}

.ft-20 {
    font-size: 20px;
}

.pd-bottom-5 {
    padding-bottom: 5px;
}

.ft-18 {
    font-size: 18px;
}

.filter-card-label {
    margin-top: 27px;
    margin-left: 20px;
    float: left;
    font-size: 19px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 27px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #092c58;
}

input:focus + .slider {
    box-shadow: 0 0 1px #092c58;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.inactive-program {
    color: #a7a2a2 !important;
    font-weight: normal;
}

.div-inactive {
    margin-top: -12px;
    margin-right: 12px;
}

.mg-top-cut-25 {
    margin-top: -25px;
}

.ft-16 {
    font-size: 16px;
}

.ht-20 {
    height: 20px;
}


.dataTables_filter {
    display: none;
 }

 table.dataTable thead .sorting_asc {
    background-image: url('/Images/sort_asc.png') !important;
  }
  table.dataTable thead .sorting_desc {
    background-image: url('/Images/sort_desc.png') !important;
  }
  table.dataTable thead .sorting_asc_disabled {
    background-image: url('/Images/sort_desc.png');
  }
  table.dataTable thead .sorting_desc_disabled {
    background-image: url('/Images/sort_asc.png');
  }
  table.dataTable thead .sorting {
    background-image: url('/Images/sort_asc.png');
}